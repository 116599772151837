import { StoryBlokService } from './blog-data.service';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Product } from '@app/core/interfaces/order.model';
import { Store } from '@ngxs/store';
import { ProductsState } from '../states/products.state';
import { FaqCategoryItem } from '../interfaces/faq-category.interface';
import { Crumb } from '../interfaces/crumb.interface';
import { CoreModule } from '../core.module';
import { DOCUMENT } from '@angular/common';
import { Verblijfsobject } from '../interfaces/verblijfsobject.model';
import { SelectionState } from '../states/selection.state';

export interface PageMetadata {
  url: string;
}

const DEFAULT_TITLE = 'KadastraleKaart.com - De gratis online kadasterkaart';
const DEFAULT_DESCRIPTION = 'KadastraleKaart.com is de gratis online kadasterkaart van Nederland, met actuele gegevens van het Kadaster over ieder kadastraal perceel en kavel.';

const DEFAULT_IMAGE = `/assets/kadastrale-kaart-preview.jpg`

@Injectable({
  providedIn: CoreModule
})
export class SeoService {
  public structuredData: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private kkStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'KadastraleKaart.com',
    legalName: 'Kadastralekaart.com',
    logo: {
      '@type': 'ImageObject',
      url: 'https://kadastralekaart.com/assets/icons/icon-512x512.png'
    },
    url: 'https://kadastralekaart.com',
    description: DEFAULT_DESCRIPTION,
    foundingDate: 2016,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Kromme Nieuwegracht 3',
      addressLocality: 'Utrecht',
      addressRegion: 'HC',
      postalCode: 3512,
      addressCountry: {
        '@type': 'Country',
        name: 'Nederland'
      }
    },
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer support',
      email: 'support@kadastralekaart.com'
    }
  };

  private localBusinessStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'KadastraleKaart.com',
    url: 'https://kadastralekaart.com',
    image: 'https://kadastralekaart.com/assets/icons/kk-icon-512x512.png',
    logo: 'https://kadastralekaart.com/assets/icons/kk-icon-512x512.png',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Kromme Nieuwegracht 2 B',
      postalCode: '3512 LP',
      addressLocality: 'Utrecht',
      addressCountry: 'NL'
    },
  }

  public websiteStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://kadastralekaart.com/',
    potentialAction: [
      {
        '@type': 'SearchAction',
        target: 'https://kadastralekaart.com/export?q={search_term_string}',
        'query-input': 'required name=search_term_string'
      }
    ],
    keywords: 'perceel,eigendomsinformatie,kadaster,kaart,maatvoering,grenzen,hypotheekinformatie,landeigendom',
    author: this.kkStructuredData
  };

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private blogDataService: StoryBlokService,
    private store: Store,
    @Inject(DOCUMENT) private document
  ) { }

  public addSeoData(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let root = this.router.routerState.snapshot.root;
      // Always set default description, but override it if another description exists. 
      this.setDefaults();
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0];
        } else if (root.data && root.data['getTitle']) {

          // dynamic
          this.setDynamicTags(root);
          this.setStructuredData(root);
          return;
        } else if (root.data && root.data['metatags']) {

          // static
          this.setStaticTags(root);
          this.setStructuredData(root);
          return;
        } else {
          return;
        }
      }
    });
  }

  public formatPostalAddressData(address: Verblijfsobject) {
    return {
      '@type': 'PostalAddress',
      addressLocality: address.woonplaatsnaam,
      postalCode: address.postcode,
      streetAddress: address.volledig_adres,
      addressCountry: 'NL'
    }
  }

  public formatFaqStructuredData(faqQuestions: FaqCategoryItem[]) {
    const formattedItems = faqQuestions.map(item => this.setFaqData(item));

    const faqPageObject = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: formattedItems,
      ...this.setBasicData()
    };

    return faqPageObject;
  }

  public formatBreadcrumbsData(crumbs: Crumb[], locationOrigin: string) {
    return {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": crumbs.map((crumb, index) => {
        return {
          "@type": "ListItem",
          "position": index + 1,
          "name": crumb.label,
          "item": locationOrigin + crumb.url
        }
      })
    }
  }

  public updateStructuredData(structuredData: any[]) {
    const currentData = this.structuredData.getValue();
    this.structuredData.next([...currentData, ...structuredData]);
  }

  public async setStructuredData(root: ActivatedRouteSnapshot) {
    const structuredData: any[] = [];
    const structuredDataObject = root.data['structuredData'];

    if (structuredDataObject) {
      if (structuredDataObject['@type'] === 'Product') {
        if (root.data.product) {
          structuredData.push(this.getProductData(root.data.product, structuredDataObject));
        } else {
          const products = this.store.selectSnapshot(ProductsState.products);
          const productPagesData = products.map(product => this.getProductData(product, structuredDataObject));
          productPagesData.forEach(page => structuredData.push(page));
        }
      }

      if (structuredDataObject['@type'] === 'FAQPage') {
        const faqPageObject = this.formatFaqStructuredData(root.data.faqItems);
        structuredData.push(faqPageObject);
      }

      if (structuredDataObject['@type'] === 'WebSite') {
        structuredData.push(this.websiteStructuredData, this.kkStructuredData, this.localBusinessStructuredData);
      }

      if (structuredDataObject['@type'] === 'NewsArticle') {
        const blogPosts: any[] = [];
        if (root.data.post) {
          blogPosts.push(root.data.post.story);
        } else {
          const stories = await this.blogDataService.listPosts('nieuws/').toPromise();
          stories.stories.forEach(story => blogPosts.push(story));
        }

        const postData = blogPosts.map(blogPost => {
          return {
            '@context': 'https://schema.org',
            ...structuredDataObject,
            ...this.setNewsArticleData(blogPost),
            ...this.setBasicData()
          };
        });

        structuredData.push(...postData);
      }
      if (structuredDataObject['@type'] === 'PostalAddress') {
        const address = this.store.selectSnapshot(SelectionState.addresses)[0];
        const postalAddress = this.formatPostalAddressData(address);
        structuredData.push(postalAddress);
      }
    }
    this.structuredData.next(structuredData);
  }


  private setBasicData() {
    return {
      name: this.titleService.getTitle(),
      description: this.metaService.getTag('name=description').content,
      url: `https://kadastralekaart.com${this.router.url}`
    };
  }

  private setOfferData(product: Product) {
    const oneMonthMs = 2592000000;
    const fiveMonths = new Date(new Date().getTime() + (oneMonthMs * 5));
    const months = `${fiveMonths.getMonth() + 1}`.padStart(2, '0');
    const days = `${fiveMonths.getDay()}`.padStart(2, '0');

    return {
      '@type': 'Offer',
      url: `https://kadastralekaart.com/producten/${product.name}`,
      price: product.price,
      priceCurrency: 'EUR',
      availability: 'http://schema.org/InStock',
      priceValidUntil: `${fiveMonths.getFullYear()}-${months}-${days}`,
      priceSpecification: {
        "price": product.price,
        "priceCurrency": "EUR",
        "valueAddedTaxIncluded": "true"
      },
      itemCondition: "https://schema.org/NewCondition",
      seller: {
        '@type': 'Organization',
        name: 'KadastraleKaart.com',
        url: 'https://kadastralekaart.com'
      },
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: "0",
          currency: "EUR"
        },
        "deliveryTime": {
          "@type": "ShippingDeliveryTime",
          "handlingTime": {
            "@type": "QuantitativeValue",
            "minValue": 0,
            "maxValue": 0,
            "unitCode": "DAY"
          },
          "transitTime": {
            "@type": "QuantitativeValue",
            "minValue": 0,
            "maxValue": 0,
            "unitCode": "DAY"
          }
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressCountry: "NL"
        }
      }
    };
  }

  private getProductData(product: Product, structuredDataObject: any) {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    const baseUrl = `https://kadastralekaart.com/assets/product-previews`;
    const defaultPreviewImageName = product.previewImageUrls[product.defaultPreviewImageIndex]
    const productData = {
      '@context': 'https://schema.org',
      '@id': `${product.id}`,
      name: product.alias,
      description: product.shortDescription,
      url: `https://kadastralekaart.com/producten/${product.name}`,
      offers: [this.setOfferData(product)],
      image: [
        `${baseUrl}/${defaultPreviewImageName}`,
        ...product.previewImageUrls.map((imageUrl) => `${baseUrl}/${imageUrl}`)
      ].filter(onlyUnique),
      brand: {
        '@type': 'Brand',
        name: 'KadastraleKaart.com'
      },
      sku: `${product.id}`,

    };
    return { ...structuredDataObject, ...productData };
  }

  private setFaqData(faqQuestion: FaqCategoryItem) {
    return {
      '@type': 'Question',
      name: faqQuestion.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faqQuestion.answer
      }
    };
  }

  private setNewsArticleData(story: any) {
    return {
      headline: story.name,
      image: story.content.image,
      datePublished: story.first_published_at,
      dateModified: story.published_at,
      description: story.content.meta_description,
      articleBody: story.content.text_temp,
      keywords: story.tag_list.join(', '),
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': `https://kadastralekaart.com${this.router.url}`
      },
      author: this.kkStructuredData,
      publisher: this.kkStructuredData
    };
  }




  private setDefaults() {
    this.setTitle(DEFAULT_TITLE);
    this.updateTag('description', DEFAULT_DESCRIPTION);
  }

  public setDynamicTags(root: ActivatedRouteSnapshot) {
    this.setTitle(root.data.getTitle(root.data, root.params));
    const tags = root.data['getMetatags'];

    this.removeImageTag();
    this.setImageTag(DEFAULT_IMAGE);

    if (tags) {
      Object.entries(tags).forEach(([tag, getValue]: [string, Function]) => {
        this.updateTag(tag, getValue(root.data, root.params));
      });
    }
  }

  public setStaticTags(root: ActivatedRouteSnapshot) {
    this.setTitle(root.data['title']);
    const tags = root.data['metatags'];

    this.removeImageTag();
    this.setImageTag(DEFAULT_IMAGE);


    if (tags) {
      Object.entries(tags).forEach(([tag, value]: [string, string]) => {
        this.updateTag(tag, value);
      });
    }
  }

  public updateTag(tagName: string, content: string) {
    if (tagName === 'image') {
      this.removeImageTag();
      this.setImageTag(content);
    } else {
      this.removeTag(tagName);
      this.metaService.addTags([
        { name: tagName, content: content },
        { property: `og:${tagName}`, content: content }
      ]);
    }
  }

  private removeTag(name: string) {
    // Removing a meta tag with 'name' property using the provided 'name'
    this.metaService.removeTag(`name='${name}'`);
    // Removing an Open Graph meta tag with 'og' property, matching the 'og:' prefix and the 'name'
    this.metaService.removeTag(`property='og:${name}'`);
  }


  private setImageTag(url: string) {
    let link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'image_src');
    link.setAttribute('href', url);
    this.document.head.appendChild(link);
    this.metaService.addTag({ property: `og:image`, content: url })

  }

  private removeImageTag() {
    const existingElement = this.document.head.querySelector(`link[rel="image_src"]`);
    if (existingElement) {
      this.document.head.removeChild(existingElement);
    }
    this.metaService.removeTag(`property='og:image'`);
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
    this.metaService.removeTag(`property='og:title'`);
    this.metaService.addTag({ property: `og:title`, content: title });
  }

}
