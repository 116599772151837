import { SubscriptionTypeForDisplay } from '@app/core/interfaces/subscription-type.model';
import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { tap } from 'rxjs/operators';
import { GroupNames } from "../interfaces/group-names.enum";
import { SubscriptionType } from "../interfaces/subscription-type.model";
import { SubscriptionService } from "../services/subscription.service";
import { ListSubscriptionTypes } from "./models/subscription-type.state.model";

@State<SubscriptionType[]>({
    name: 'subscriptionTypes',
    defaults: []
})
@Injectable()
export class SubscriptionTypesState {
    // Return all subscription types
    @Selector([SubscriptionTypesState])
    static subscriptionTypes(state: SubscriptionType[]): SubscriptionType[] {
        return state;
    }

    // Dynamic selector notation for returning only 1 task
    static getSubscriptionTypeByName(name: string) {
        return createSelector(
            [SubscriptionTypesState.subscriptionTypes],
            (subscriptionTypes: SubscriptionType[]) => {
                return subscriptionTypes.find(subscriptionType => subscriptionType.name === name);
            }
        );
    }

    // Dynamic selector notation for returning only 1 task
    static getSubscriptionTypeById(id: string) {
        return createSelector(
            [SubscriptionTypesState.subscriptionTypes],
            (subscriptionTypes: SubscriptionType[]) => {
                return subscriptionTypes.find(subscriptionType => subscriptionType.id === id);
            }
        );
    }

    @Selector([SubscriptionTypesState.getSubscriptionTypeByName(GroupNames.plus), SubscriptionTypesState.getSubscriptionTypeByName(GroupNames.pro)])
    static subscriptionTypesForDisplay(plus: SubscriptionType, pro: SubscriptionType): SubscriptionTypeForDisplay[] {
        return [{
            ...plus,
            type: GroupNames.plus,
            displayDescription: 'Uitgebreide functionaliteit.',
            color: '#83c77c',
            iconUrl: 'assets/upgrade/plus-user.svg',
        }, {
            ...pro,
            type: GroupNames.pro,
            displayDescription: 'Professionele functionaliteit.',
            color: '#6ab0c7',
            iconUrl: 'assets/upgrade/pro-user.svg',
        }];
    }

    constructor(private subscriptionService: SubscriptionService) { }

    @Action(ListSubscriptionTypes)
    listSubscriptionTypes(ctx: StateContext<SubscriptionType[]>) {
        return this.subscriptionService.fetchSubscriptionTypes$().pipe(
            tap(subscriptionTypes => {
                ctx.setState(subscriptionTypes)
            })
        )

    }
}

