import { AsyncPipe, NgOptimizedImage, TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core/services/app.service';
import { AuthNewService } from '@app/core/services/auth-new.service';
import { UserState } from '@app/core/states/user.state';
import { Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, filter, map, shareReplay, startWith } from 'rxjs';
import { MenuComponent } from '../menu/menu.component';
import { ClearUser } from '@app/core/states/models/user.state.model';
import { GroupNames } from '@app/core/interfaces/group-names.enum';
import { ShoppingCartState } from '@app/core/states/shopping-cart.state';
import { StoryBlokService } from '@app/core/services/blog-data.service';
import { AppState } from '@app/core/states/app.state';
import dayjs from 'dayjs';
import { SubscriptionIndicatorChipComponent } from '@app/shared/subscription-indicator-chip/subscription-indicator-chip.component';
import { SetLastRead } from '@app/core/states/models/app.state.model';

@Component({
  selector: 'app-account-button',
  standalone: true,
  imports: [AsyncPipe, NgOptimizedImage, MenuComponent, TitleCasePipe, SubscriptionIndicatorChipComponent],
  templateUrl: './account-button.component.html',
  styleUrl: './account-button.component.scss'
})
export class AccountButtonComponent {
  public user$ = this.store.select(UserState.user);
  public profile$ = this.authNew.userProfile$
  public currentSubscription$ = this.store.select(UserState.activeSubscription).pipe(
    filter(subscription => subscription !== null && subscription !== undefined),
  );
  public isMobile$ = this.appService.mobileView$;
  public isAdmin$ = this.authNew.isInGroup$(GroupNames.admin).pipe(shareReplay());
  public itemsInCart$ = this.store.select(ShoppingCartState.cartItems).pipe(
    map((items) => items.length)
  );
  public showAccountMenu$ = new BehaviorSubject<boolean>(false);
  public notifications$ = this.storyBlokService.listPosts('nieuws/', { perPage: 3, withTag: ['Notification'] }).pipe(
    map(response => response.stories),
    startWith([]),
    shareReplay()
  );

  public lastRead$ = this.store.select(AppState.lastRead).pipe(
    map(lastRead => {
      return lastRead === undefined || lastRead === null ? dayjs().subtract(5, 'year').toDate() : lastRead
    })
  );

  public notificationsWithReadStatus$ = combineLatest([this.lastRead$, this.notifications$]).pipe(
    map(([lastRead, notifications]) => {
      return notifications.map((notification) => {
        return {
          ...notification,
          unread: new Date(lastRead).getTime() < new Date(notification.created_at).getTime()
        }
      })
    }),
  );

  public notificationsMenuItems$ = this.notificationsWithReadStatus$.pipe(
    map(stories => {
      const items = stories.map(story => {
        return {
          label: story.name,
          items: [],
          routerLink: `/nieuws/${story.slug}`,
          showRedDot: story.unread,
          // thumb: story.content.image,
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-file'
        };
      });

      return [{
        label: 'Notificaties',
        items: items,
        showRedDot: stories.map(story => story.unread).includes(true),
        icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-bell'
      }]
    })
  )


  public loggedInMenuItems$ = combineLatest([this.isAdmin$, this.itemsInCart$, this.notificationsMenuItems$]).pipe(
    startWith([false, 0, [] as any]),
    map(([isAdmin, itemsInCart, notifications]) => {
      return [
        {
          label: 'Upgrade uw account',
          items: [],
          routerLink: '/upgrade',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-star',
          emphasize: true
        },
        {
          label: 'Admin',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-settings',
          hide: isAdmin === false,
          items: [
            {
              label: 'Gebruikers',
              icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-user',
              routerLink: '/admin/user-management',
              items: []
            },
            {
              label: 'Orders',
              icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-shopping-cart',
              routerLink: '/admin/order-item-management',
              items: []
            },
            {
              label: 'Dashboard ',
              icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-euro',
              routerLink: '/admin/management-dashboard',
              items: []
            },
            {
              label: 'Blacklist',
              icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-help-circle',
              routerLink: '/admin/blacklist',
              items: []
            },
            {
              label: 'API-keys',
              icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-external-link',
              routerLink: '/admin/api-key-management',
              items: []
            }
          ]
        },
        {
          label: 'Mijn winkelwagen',
          subLabel: itemsInCart === 1 ? `${itemsInCart} product` : `${itemsInCart} producten`,
          items: [],
          routerLink: '/afrekenen/besteloverzicht',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-shopping-cart'
        },
        {
          label: 'Mijn percelen',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-layout',
          routerLink: '/profiel/mijn-percelen',
          items: []
        },
        {
          label: 'Mijn gebeurtenis-alerts',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-bell',
          routerLink: '/profiel/mijn-alerts',
          items: []
        },
        {
          label: 'Mijn bestellingen',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-euro',
          routerLink: '/profiel/mijn-bestellingen',
          items: []
        },
        {
          label: 'Mijn facturen',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-file-text',
          routerLink: '/profiel/mijn-facturen',
          items: []
        },
        ...notifications,
        {
          label: 'Accountbeheer',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-settings',
          routerLink: '/profiel/beheer',
          items: []
        },

        {
          label: 'Uitloggen',
          items: [],
          click: () => this.logout(),
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-log-out',
        },
      ];
    })
  )

  public loggedOutMenuItems$ = combineLatest([this.itemsInCart$, this.notificationsMenuItems$]).pipe(
    startWith([0, [] as any]),
    map(([itemsInCart, notifications]) => {
      return [
        {
          label: 'Upgrade uw account',
          items: [],
          routerLink: '/upgrade',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-star',
          emphasize: true
        },
        {
          label: 'Inloggen',
          items: [],
          click: () => this.login(),
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-user',
        },
        {
          label: 'Registeren',
          items: [],
          click: () => this.signup(),
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-plus-circle',
        },

        // {
        //   label: 'Ontdek alles van plus & pro',
        //   items: [
        //     {
        //       label: 'Meten',
        //       items: [],
        //       routerLink: '/upgrade/meten',
        //       icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-repeat'
        //     },
        //     {
        //       label: 'API',
        //       items: [],
        //       routerLink: '/upgrade/api',
        //       icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-repeat'
        //     },
        //   ],
        //   icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-star'
        // },
        {
          label: 'Mijn winkelwagen',
          subLabel: itemsInCart === 1 ? `${itemsInCart} product` : `${itemsInCart} producten`,
          items: [],
          routerLink: '/afrekenen/besteloverzicht',
          icon: 'assets/icons/svg/all_svg_icons-8.svg#icon-shopping-cart'
        },
        ...notifications,
      ];
    })
  )


  constructor(
    private store: Store,
    private authNew: AuthNewService,
    private router: Router,
    private storyBlokService: StoryBlokService,
    private appService: AppService) {
  }

  public login() {
    this.authNew.login(this.router.url.split('(')[0]);
  }

  public signup() {
    this.authNew.login(this.router.url.split('(')[0], 'signup');
  }

  public async logout() {
    await this.store.dispatch(new ClearUser()).toPromise()
    return this.authNew.logout();
  }

  public openMenu() {
    this.showAccountMenu$.next(true);
  }

  public closeMenu() {
    this.showAccountMenu$.next(false);
  }




  public hasUnreadMessages$ = this.notificationsWithReadStatus$.pipe(
    map(messages => {
      return messages.filter(message => message.unread === true)
    }),
    map(unreadMessages => {
      return unreadMessages.length > 0;
    })
  );
  public markAsRead() {
    this.store.dispatch(new SetLastRead());
  }






}


