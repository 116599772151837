import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthNewService } from '@app/core/services/auth-new.service';
import { CoreModule } from '../core.module';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: CoreModule
})
export class LoggedInGuard  {
  constructor(
    private auth: AuthNewService
  ) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const loginHint = next.queryParams['loginHint']
    const isAuthenticated = await this.auth.isAuthenticated$.pipe(
      take(1)
    ).toPromise();
    if (!isAuthenticated) {
      this.auth.login(state.url, loginHint);
    }
    
    return isAuthenticated;
  }
}
