import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { UnderscoreToSpace } from '@app/shared/underscore-to-space.pipe';

@NgModule({
  imports: [
    UnderscoreToSpace
  ],
  providers: [
    DatePipe,
    provideHttpClient(withInterceptorsFromDi())
  ] 
})
export class CoreModule {}
