import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthNewService, Auth0User } from '@app/core/services/auth-new.service';
import { filter, tap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CoreModule } from '../core.module';
@Injectable({
  providedIn: CoreModule
})
export class ProfileResolver  {
  constructor(private auth: AuthNewService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.auth.userProfile$.pipe(
      filter(userProfile => userProfile !== null),
      take(1)
    );
  }
}
