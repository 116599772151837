import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CoreModule } from '../core.module';
import { UserState } from '../states/user.state';

@Injectable({
  providedIn: CoreModule
})
export class UserDetailsGuard  {
  constructor(
    private store: Store,
    private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    const user = this.store.selectSnapshot(UserState.user);

    if (!user) {
      return true;
    }

    if (user?.organisationName === null) {
      this.router.navigate(['/', 'profiel', 'gegevens'], {
        queryParams: next.queryParams,
      });
      return false;
    }

    if (!user?.sector) {
      this.router.navigate(['/', 'profiel', 'sector'], {
        queryParams: next.queryParams,
      });
      return false;
    }

    return true;
  }

}
