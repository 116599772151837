<app-header></app-header>
<router-outlet></router-outlet>
<div>
    <router-outlet #outlet="outlet" name="winkelwagen"></router-outlet>
</div>
<app-structured-data></app-structured-data>

@defer(when openConsentWindow) {
    <app-cookie-consent-modal></app-cookie-consent-modal>
}
