import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext, createSelector } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { Verblijfsobject } from "../interfaces/verblijfsobject.model";
import { AddressService } from "../services/address.service";
import { BgtService } from "../services/bgt.service";
import { AhnService } from "../services/ahn.service";
import { ParcelService } from "../services/parcel.service";
import { SelectionStateModel, SetParcelSelectionById, SelectAddressesWithinParcel, SetParcelSelectionByAddressId, SetAddressIndexForWozPage, SetBronhoudersByParcel, ClearBronhoudersSelection, SetBedekkingenByParcel, ClearBedekkingenSelection, SetDso, ClearSelection, ClearPlanSelection, SetDsoStyling, SelectRelatedParcels, SetAhnByParcel, ClearAhnSelection } from "./models/selection.state.model";
import { firstValueFrom } from "rxjs";

const defaultState = {
    parcel: null,
    relatedParcels: [],
    addresses: [],
    bestemmingsplannen: [],
    bronhouders: [],
    bedekkingen: [],
    addressIndexForWozPage: null,
    dso: null,
    dsoStyling: null,
    ahn: null,
};

@State<SelectionStateModel>({
    name: 'selection',
    defaults: defaultState
})
@Injectable()
export class SelectionState {
    @Selector([SelectionState])
    static parcel(state: SelectionStateModel) {
        return state.parcel;
    }

    @Selector([SelectionState])
    static relatedParcels(state: SelectionStateModel) {
        return state.relatedParcels;
    }

    @Selector([SelectionState])
    static addressIndexForWozPage(state: SelectionStateModel) {
        return state.addressIndexForWozPage;
    }

    @Selector([SelectionState])
    static addresses(state: SelectionStateModel) {
        return state.addresses;
    }


    @Selector([SelectionState])
    static bronhouders(state: SelectionStateModel) {
        return state.bronhouders;
    }

    @Selector([SelectionState])
    static bedekkingen(state: SelectionStateModel) {
        return state.bedekkingen;
    }

    @Selector([SelectionState])
    static ahn(state: SelectionStateModel) {
        return state.ahn;
    }

    @Selector([SelectionState])
    static dso(state: SelectionStateModel) {
        return state.dso;
    }

    @Selector([SelectionState])
    static dsoStyling(state: SelectionStateModel) {
        return state.dsoStyling;
    }


    // Note: Only use this selector for the WOZ page
    @Selector([SelectionState.addresses, SelectionState.addressIndexForWozPage])
    static wozAddress(addresses: Verblijfsobject[], addressIndexForWozPage: number) {
        return addresses[addressIndexForWozPage];
    }

    // Dynamic selector notation for returning only 1 address
    static getAddressByNummeraanduidingId(nummeraanduidingId: string) {
        return createSelector(
            [SelectionState.addresses],
            (addresses: Verblijfsobject[]) => {
                return addresses.find(address => address.nummeraanduidingidentificatie === nummeraanduidingId);
            }
        );
    }

    @Selector([SelectionState.addresses])
    static postcodes(state: Verblijfsobject[]) {
        return state.slice().map(address => address.postcode)
            .filter((v, i, a) => a.indexOf(v) === i)
            .filter((postcode) => postcode !== null)
            .map(postalCode => postalCode.substring(0, 4) + ' ' + postalCode.substring(4));
    }

    @Selector([SelectionState])
    static bestemmingsplannen(state: SelectionStateModel) {
        return state.bestemmingsplannen;
    }

    constructor(
        private parcelService: ParcelService,
        private addressService: AddressService,
        private bgtService: BgtService,
        private ahnService: AhnService,
    ) { }

    @Action(SetParcelSelectionById)
    async setParcelSelectionById(ctx: StateContext<SelectionStateModel>, action: SetParcelSelectionById) {
        const parcel = await firstValueFrom(this.parcelService.get(
            action.payload.gemeente,
            action.payload.sectie,
            action.payload.perceelnummer
        ));
        ctx.patchState({
            parcel: parcel,
            bronhouders: [],
            bedekkingen: [],
            ahn: null,
        });
    }

    @Action(SetDso)
    async setDso(ctx: StateContext<SelectionStateModel>, action: SetDso) {
        ctx.patchState({
            dso: action.payload.dso
        });
    }

    @Action(SetDsoStyling)
    async setDsoStyling(ctx: StateContext<SelectionStateModel>, action: SetDsoStyling) {
        ctx.patchState({
            dsoStyling: action.payload.dsoStyling
        });
    }

    @Action(SetParcelSelectionByAddressId)
    async setParcelSelectionByAddressId(ctx: StateContext<SelectionStateModel>, action: SetParcelSelectionByAddressId) {
        const parcel = await firstValueFrom(this.addressService.getRelatedParcel(action.payload.nummeraanduidingid));
        // Dit moet misschien wel naar de backend, maar dat voelde ook onlogisch. Voor nu hier laten staan totdat we meer met de related parcels gaan doen.
        const relatedParcels = await firstValueFrom(this.addressService.getExtraRelatedParcel(action.payload.nummeraanduidingid));

        ctx.patchState({
            parcel: parcel,
            relatedParcels: relatedParcels.filter(relatedParcel => `${relatedParcel.gemeente}${relatedParcel.sectie}${relatedParcel.perceelnummer}` !== `${parcel.gemeente}${parcel.sectie}${parcel.perceelnummer}`),
            bronhouders: []
        });
    }

    @Action(SelectRelatedParcels)
    async selectRelatedParcels(ctx: StateContext<SelectionStateModel>, action: SelectRelatedParcels) {
        const relatedParcels = await firstValueFrom(this.parcelService.getRelatedParcels(action.payload.gemeente, action.payload.sectie, action.payload.perceelnummer));
  
        ctx.patchState({
            relatedParcels,
        });
    }

    @Action(SetBronhoudersByParcel)
    setBronhoudersByParcel(ctx: StateContext<SelectionStateModel>, action: SetBronhoudersByParcel) {
        return this.bgtService.listBronhouders(action.payload).pipe(
            tap(bronhouders => {
                ctx.patchState({ bronhouders: bronhouders });
            })
        );
    }

    @Action(SetBedekkingenByParcel)
    setBedekkingenByParcel(ctx: StateContext<SelectionStateModel>, action: SetBedekkingenByParcel) {
        return this.bgtService.listBedekkingen(action.payload).pipe(
            tap(bedekkingen => {
                ctx.patchState({ bedekkingen: bedekkingen });
            })
        );
    }

    // Kan geen buffer meesturen als state, dus daarom url meesturen (string), dus daarom geen pipe mogelijk
    @Action(SetAhnByParcel)
    SetAhnByParcel(ctx: StateContext<SelectionStateModel>, action: SetAhnByParcel) {
        const ahn = this.ahnService.getAhnImageUrl(action.payload);
        ctx.patchState({ ahn: ahn });
    }
    

    @Action(SelectAddressesWithinParcel)
    updateAddressesSelection(ctx: StateContext<SelectionStateModel>, action: SelectAddressesWithinParcel) {
        return this.parcelService.getAddressWithinParcel(
            action.payload.gemeente,
            action.payload.sectie,
            action.payload.perceelnummer).pipe(
                tap(addresses => {
                    ctx.patchState({ addresses: addresses });
                })
            );
    }

    @Action(SetAddressIndexForWozPage)
    SetAddressIndexForWozPage(ctx: StateContext<SelectionStateModel>, action: SetAddressIndexForWozPage) {
        ctx.patchState({
            addressIndexForWozPage: action.payload.index
        });
    }


    @Action(ClearBronhoudersSelection)
    clearBronhoudersSelection(ctx: StateContext<SelectionStateModel>) {
        ctx.patchState({
            bronhouders: []
        });
    }

    @Action(ClearBedekkingenSelection)
    clearBedekkingenSelection(ctx: StateContext<SelectionStateModel>) {
        ctx.patchState({
            bedekkingen: []
        });
    }

    @Action(ClearAhnSelection)
    clearAhnSelection(ctx: StateContext<SelectionStateModel>) {
        ctx.patchState({
            ahn: null
        });
    }

    @Action(ClearPlanSelection)
    clearPlanSelection(ctx: StateContext<SelectionStateModel>) {
        ctx.patchState({
            dso: null
        })
    }

    @Action(ClearSelection)
    clearSelection(ctx: StateContext<SelectionStateModel>) {
        ctx.setState(defaultState)
    }
}

