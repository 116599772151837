import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { GroupNames } from '@app/core/interfaces/group-names.enum';
import { AuthNewService } from '@app/core/services/auth-new.service';
import { CoreModule } from '../core.module';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: CoreModule
})
export class GroupGuard  {
  constructor(private auth: AuthNewService, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const group: GroupNames = next.data['group'];
    const isAuthenticated = await this.auth.isAuthenticated$.pipe(take(1)).toPromise();
    const inGroup = await this.auth
      .isInGroup$(group)
      .pipe(take(1))
      .toPromise();
    if (!inGroup) {
      this.router.navigate([next.data['redirectTo']]);
    }

    return inGroup;
  }
}
