<div class="headerspacer"></div>
<div class="header" [ngStyle]="{'top.px': this.headerDisplacement - 58 }">

  @if ((hideItems$ | async) === false) {

  @if ((isMobile$ |async)=== true ) {
  <span (click)="toggleShowItem('menu')" class="header__button header__button--menu" aria-label="Menu">
    <svg height="16" width="16" class="icon">
      <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-menu"></use>
    </svg>
  </span>
  }
  }

  <a routerLink="/" class="header__logolink">
    <h1 class="header__logo">KadastraleKaart.com</h1>
  </a>

  @if ((hideItems$ | async) === false) {
  <div class="header__buttons header__buttons--left">
    @if ((isPro$ | async) === false) {
      <a routerLink="/upgrade" class="header__button">
        <span class="icon--override">
          <svg height="16" width="16" class="icon">
            <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-star"></use>
          </svg>
        </span>
        Upgrade

  
      </a>
      }
    <span (click)="toggleShowItem('mainDesktopNavMenu')" class="header__button"
      [class.header__button--open]="showItem === 'mainDesktopNavMenu'">
      Kaarten
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-chevron-down"></use>
      </svg>
    </span>



    <a routerLink="/producten" class="header__button">
      Webwinkel
    </a>

    <span (click)="toggleShowItem('moreDesktopNavMenu')" class="header__button"
      [class.header__button--open]="showItem === 'moreDesktopNavMenu'">
      Meer
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-chevron-down"></use>
      </svg>
    </span>

  </div>

  }

  <app-spin class="header__spinner"> </app-spin>

  <div class="header__buttons header__buttons--right">
    @if((hideItems$ | async) === false) {
    <span class="header__button header__button--account" (click)="toggleShowItem(null)">
      <app-account-button> </app-account-button>
    </span>
    }

    @if((hideItems$ | async) === false) {
    <a [class.header__button--cart--empty]="(itemsInCart$ | async) === 0"
      [routerLink]="['/', 'afrekenen', 'besteloverzicht']" class="header__button header__button--cart"
      aria-label="Winkelwagen">
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-shopping-cart"></use>
      </svg>
      @if (itemsInCart$ | async) {
      <span class="header__button__counter">
        {{ itemsInCart$ | async }}
      </span>
      }

    </a>
    }
  </div>
</div>

@if (showItem === 'menu') {
<div (click)="toggleShowItem(null)" class="menu-container-backdrop"></div>
<div class="menu-container">
  <div class="menu-container__header">
    <h1 class="menu-container__header__logo">KadastraleKaart.com</h1>
    <div class="menu-container__close" (click)="toggleShowItem(null)">
      <svg height="16" width="16" class="icon">
        <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-x"></use>
      </svg>
    </div>
  </div>

  @defer (on viewport; prefetch on idle) {
  <app-menu menuType="headermenu-mobile" [menuItems]="mobileMenuItems$ | async" (closeMenuEmitter)="closeMenuAfterNavigation()"
    [showDisclaimer]="false"> </app-menu>
  } @placeholder {
  <div>
    <a routerLink="/upgrade">Upgrade</a>
    <a routerLink="/maatwerk">Maatwerk</a>
    <a routerLink="/support">Contact</a>
    <a routerLink="/producten">Producten</a>
    <a routerLink="/handleiding">Handleiding</a>
    <a routerLink="/nieuws">Nieuws</a>
  </div>
  }
</div>
}
@if (showItem === 'mainDesktopNavMenu') {
@defer( when showItem === 'mainDesktopNavMenu'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)">
  <app-menu (click)="toggleShowItem(null)" menuType="headermenu-desktop-maps" [menuItems]="mainDesktopNavMenu$ | async"
    [showDisclaimer]="false"></app-menu>
</app-header-menutray>
}
}

@if (showItem === 'moreDesktopNavMenu') {
@defer (when showItem === 'moreDesktopNavMenu'; prefetch on idle) {
<app-header-menutray (close)="toggleShowItem(null)">
  <app-menu (click)="toggleShowItem(null)" menuType="headermenu-desktop-more" [menuItems]="moreDesktopNavMenu"
    [showDisclaimer]="false"></app-menu>
</app-header-menutray>
}
}



<!-- TAKE NOTE: Test dit met Chrome op responsive mode en iPhone SE, en zorg dat deze tekst slechts een regel is. Anders is gans de interface kaput -->

<!-- <div *ngIf="(bannerClose$ | async) === false" class="banner-notification">
  <span class="banner-notification__text">

    Help mee met het <span (click)="handleBanner()" class="banner-notification__link">verbeteren van
      KadastraleKaart.com</span>.

    
  </span>
  <svg height="20" width="20" class="icon banner-notification__icon" (click)="close()">
    <use xlink:href="assets/icons/svg/all_svg_icons-8.svg#icon-x"></use>
  </svg>
</div> -->