import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { CoreModule } from '@app/core/core.module';
import { distinctUntilChanged, fromEvent, map, Observable, startWith, tap } from 'rxjs';
import { MIN_DESKTOP_WIDTH } from '../config/config';
import { AppState } from '../states/app.state';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
@Injectable({
  providedIn: CoreModule
})
export class AppService {

  private resizeEvent$ = fromEvent(this.document.defaultView, 'resize');
  public innerWidth$ = this.getScreenWidth$(this.resizeEvent$);

  public mobileView$ = this.innerWidth$.pipe(
    map(width => width <= this.MOBILE_MAX_WIDTH),
  );

  private MOBILE_MAX_WIDTH = MIN_DESKTOP_WIDTH;

  constructor(@Inject(DOCUMENT) private document: Document, private store: Store, private router: Router) {
  }

  private getScreenWidth$(resizeEvent$: Observable<Event>) {
    return resizeEvent$.pipe(
      map(event => (event.target as Window).innerWidth),
      startWith(this.document.defaultView.innerWidth),
      distinctUntilChanged()
    );
  }

  private getScreenWidth() {
    return this.document.defaultView.innerWidth
  }

  public hasMinDesktopWidth() {
    const width = this.getScreenWidth();
    return width > MIN_DESKTOP_WIDTH;
  }

  public async goToPreviousPage() {
    const previousUrl = this.store.selectSnapshot(AppState.previousRoute);
    this.router.navigateByUrl(previousUrl);
  }

  public lazyLoadStyle(bundleName: string) {
    // bundleName refers to the name of a css bundle that has {inject:false} in the angular.json.
    // Note: it is import that if you update the external package, you also update the bundlename in order to make sure
    // clients do not have a cached version of the css.

    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      bundleName
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = `${bundleName}.css`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${bundleName}.css`;

      head.appendChild(style);
    }
  }

}

