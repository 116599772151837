import { PropertyDescription } from '@app/core/config/user-properties';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile, User } from '../interfaces/user.model';
import { UserParcel } from '../interfaces/user-parcel.model';
import { ApplicationSubscription } from '../interfaces/subscription.model';
import { environment } from 'environments/environment';
import { Order } from '../interfaces/order.model';
import { BasicMollieSubscription } from '../interfaces/types';
import { CoreModule } from '@app/core/core.module';
import { saveAs } from 'file-saver';
import { Permit } from 'shared-types';
import { HttpClient } from '@angular/common/http';
import { ApiKey } from '../interfaces/api-key.model';

@Injectable({
  providedIn: CoreModule
})
export class UserService {
  private apiLocation = environment.apiDomain + environment.apiUrl;

  constructor(private http: HttpClient) {}

  public list(params: {q: string | null | undefined, subscriptionType?: string | null | undefined}): Observable<User[]> {
    return this.http.get<User[]>(this.apiLocation + '/admin/user', {
      params
    });
  }

  public getAdminUser(auth0Id: string) {
    return this.http.get<User & { auth0User: Profile, userParcels: UserParcel[], orders: Order[], notifications: any[]}>(this.apiLocation + `/admin/user/${auth0Id}`)
  }

  public get(auth0Id: string) {
    return this.http.get<User>(this.apiLocation + `/user/${auth0Id}`);
  }

  public upsert(auth0Id: string) {
    /* Will automatically create a user in the database if it does not yet exist */
    return this.http.post<User>(this.apiLocation + `/user/${auth0Id}`, {});
  }

  public update(auth0Id: string, user: User) {
    return this.http.put<User>(this.apiLocation + `/user/${auth0Id}`, user);
  }

  public adminUpdate(auth0Id: string, user: User) {
    return this.http.put<User>(this.apiLocation + `/admin/user/${auth0Id}`, user);
  }

  public delete(auth0Id: string) {
    return this.http.delete<void>(this.apiLocation + `/user/${auth0Id}`);
  }

  public getMyOrders(auth0Id: string) {
    return this.http.get<Order[]>(this.apiLocation + `/user/${auth0Id}/order`);
  }

  public getSubscriptionUsage(auth0Id: string) {
    /* Will automatically create a user in the database if it does not yet exist */
    return this.http.get<any>(this.apiLocation + `/user/${auth0Id}/subscription-usage`);
  }

  public getInvoiceAddresses(auth0Id: string) {
    return this.http.get<string>(this.apiLocation + `/user/${auth0Id}/invoice-address`);
  }

  public updateUserInvoiceAddress(auth0Id: string, updatedInvoiceAddress: string) {
    return this.http.patch<string>(this.apiLocation + `/user/${auth0Id}/invoice-address`, {
      invoiceAddress: updatedInvoiceAddress
    });
  }
    
  public listInvoices(auth0Id: string) {
    return this.http.get<{id: string, invoice_id: string, invoice_date: string}[]>(`${this.apiLocation}/user/${auth0Id}/invoice`);
  }

  public downloadInvoice(auth0Id: string, invoiceId: string) {
    return this.http.get<Blob>(`${this.apiLocation}/user/${auth0Id}/invoice/${invoiceId}/pdf`, {
      responseType: 'blob' as any
    });
  }

  public getMandate(auth0Id: string) {
    return this.http.get<string>(this.apiLocation + `/user/${auth0Id}/mandate`);
  }

  public updateMandate(auth0Id: string) {
    return this.http.patch<any>(this.apiLocation + `/user/${auth0Id}/mandate`, { });
  }

  public listFilesForMap(auth0Id: string) {
    return this.http.get<any[]>(this.apiLocation + `/user/${auth0Id}/file`);
  }

  public listOrders(auth0Id: string) {
    return this.http.get<Order[]>(`${this.apiLocation}/admin/user/${auth0Id}/order`);
  }

  public createActiveSubscription(auth0Id: string, subscriptionTypeId: string) {
    return this.http.post(this.apiLocation + `/admin/user/${auth0Id}/subscription/status/active`, {
      subscriptionTypeId: subscriptionTypeId
    });
  }

  public listUserSubscriptions(auth0Id: string) {
    return this.http.get<ApplicationSubscription[]>(this.apiLocation + `/user/${auth0Id}/subscription`);
  }

  public cancelSubscription(subscriptionId: string, directCancel = false) {
    const queryParams = { directCancel: JSON.stringify(directCancel) };
    return this.http.delete(this.apiLocation + `/admin/subscription/${subscriptionId}`, {
      params: queryParams
    });
  }

  public requestMandate(subscriptionTypeId: string, paymentTermName: string) {
    return this.http.post<any>(this.apiLocation + '/payment/verification', {
      subscriptionTypeId: subscriptionTypeId,
      paymentTermName: paymentTermName
    });
  }

  public addParcel(auth0Id: string, userParcel: Partial<UserParcel>) {
    return this.http.post<UserParcel>(this.apiLocation + `/user/${auth0Id}/parcel`, userParcel);
  }

  public getParcel(auth0Id: string, parcelId: string) {
    return this.http.get<UserParcel>(this.apiLocation + `/user/${auth0Id}/parcel/${parcelId}`);
  }

  public getPermits(auth0Id: string, parcelId: string) {
    return this.http.get<Permit[]>(this.apiLocation + `/user/${auth0Id}/parcel/${parcelId}/permits`);
  }

  public updateParcel(userParcelId, userParcel: UserParcel) {
    return this.http.put<UserParcel>(
      this.apiLocation + `/user/${userParcel.userAuth0Id}/parcel/${userParcelId}`,
      userParcel
    );
  }

  public listParcels(params: {
    auth0Id: string;
    limit: string;
    offset: string;
    tags?: string[];
    searchTerm?: string;
    minSize?: string;
    maxSize?: string;
    bbox?: string;
  }) {
    return this.http.get<{
      count: number;
      limit: string;
      offset: string;
      rows: UserParcel[];
    }>(this.apiLocation + `/user/${params.auth0Id}/parcel`, {
      params: params
    });
  }

  public listTags(auth0Id: string) {
    return this.http.get<string[]>(this.apiLocation + `/user/${auth0Id}/tag`);
  }

  public hasValidMandate() {
    return this.http.get<boolean>(this.apiLocation + '/mandate');
  }

  public removeParcel(parcelId: string, userAuth0Id: string) {
    return this.http.delete<void>(this.apiLocation + `/user/${userAuth0Id}/parcel/${parcelId}`);
  }

  public getCurrentActiveSubscription(user: User) {
    const activeSubscriptions = user.subscriptions.filter(s => s.status === 'active');
    return activeSubscriptions[0];
  }

  public getMollieSubscriptions(auth0Id: string) {
    return this.http.get<BasicMollieSubscription[]>(this.apiLocation + `/admin/user/${auth0Id}/mollie-subscription`);
  }

  public listApiKeys(q: string) {
    return this.http.get<ApiKey[]>(this.apiLocation + '/admin/api-key', {
      params: {
        q
      }
    });
  }

  public syncAuth0Permissons(auth0Id: string) {
    return this.http.get(this.apiLocation + `/user/${auth0Id}/sync-auth0-permissions`);
  }
  
  public listUserApiKeys(auth0Id: string) {
    return this.http.get<{id: string; createdAt: string}[]>(this.apiLocation + `/user/${auth0Id}/api-key`);
  }

  public disableApiKey(auth0Id: string, apiKeyId: string) {
    return this.http.delete(this.apiLocation + `/user/${auth0Id}/api-key/${apiKeyId}`);
  }

  public createApiKey(auth0Id: string) {
    return this.http.post(this.apiLocation + `/user/${auth0Id}/api-key`, null);
  }

  public downloadUserParcelSpreadSheet(userParcelArray: UserParcel[], userProperties: PropertyDescription[]) {
    const csvData = this.convertUserParcelsToCSV(userParcelArray, userProperties);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const filename = 'opgeslagen-percelen-KadastraleKaart.com';
    saveAs(blob, `${filename}.csv`);
  }

  private convertUserParcelsToCSV(userParcelArray: UserParcel[], userProperties: PropertyDescription[]) {
    const headerList = [...userProperties];
    let csvBody = 'sep=;' + '\r\n';
    let row = '';
    row += headerList.map(header => header.alias).join(';');
    csvBody += row + '\r\n';

    userParcelArray.forEach(userParcel => {
      const line = headerList.map(header => {
        const flattenedParcel = {
          ...userParcel,
          ...userParcel.brkParcel,
          ...userParcel.userProperties
        };
        const userParcelProperty = flattenedParcel[header.name] || '';
        return `${userParcelProperty}`.replace(/(\r\n|\n|\r)/gm, ' ').replace(/[;]+/g, ':'); // Remove all line breaks from description; replace all semicolons with ':'
      });
      csvBody += line.join(';') + '\r\n';
    });
    return csvBody;
  }
}
