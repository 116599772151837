import { Injectable } from '@angular/core';
import { from, of, BehaviorSubject, throwError, Subject, merge } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GroupNames } from '../interfaces/group-names.enum';
import { CoreModule } from '@app/core/core.module';
import { environment } from 'environments/environment';

import { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';

export interface Auth0User {
    email: string;
    email_verified: boolean;
    ['https://kadastralekaart.com/app_metadata']?: {
        authorization: {
            groups: string[]
        }
    };
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    updated_at: string;
}

@Injectable({
    providedIn: CoreModule
})
export class AuthNewService {
    // Create an observable of Auth0 instance of client (and import it dynamically)
    public auth0Client$ = of(Auth0Client).pipe(
        map((Auth0Client) => {
            return new Auth0Client({
                clientId: environment.auth0ClientId,
                cacheLocation: 'localstorage',
                domain: 'login.kadastralekaart.com',
                authorizationParams: {
                    audience: 'https://api.kadastralekaart.com/api',
                },
                useRefreshTokens: true,
                useRefreshTokensFallback: true
            });
        }),
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    )

    public initialAuthState$ = this.auth0Client$.pipe(
        concatMap((client) => from(client.isAuthenticated())),
    );
    public authStateAfterParse$ = new Subject<boolean>();

    public isAuthenticated$ = merge(this.initialAuthState$, this.authStateAfterParse$).pipe(
        distinctUntilChanged(),
        tap((isAuthenticated) => this.loggedIn = isAuthenticated)
    );

    public handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client) => from(client.handleRedirectCallback())),
    );
    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<Auth0User>(null);
    public userProfile$ = this.userProfileSubject$.asObservable();
    public groups$ = this.userProfile$.pipe(
        map(user => {
            return user?.["https://kadastralekaart.com/app_metadata"]?.authorization?.groups || [];
        }),
        shareReplay(1)
    );

    // Create a local property for login status
    public loggedIn: boolean = null;

    constructor(private router: Router) {
        // On initial load, check authentication state with authorization server
        // Set up local auth streams if user is already authenticated
        this.localAuthSetup();
        // Handle redirect from Auth0 login
        this.handleAuthCallback();
    }

    private localAuthSetup() {
        // This should only be called on app initialization
        // Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return this.getUser$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe();
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    public getUser$() {
        return this.auth0Client$.pipe(
            concatMap((client) => from(client.getUser())),
            tap(user => this.userProfileSubject$.next(user as any)),
        );
    }

    public getTokenSilently$(options?: GetTokenSilentlyOptions) {
        return this.auth0Client$.pipe(
            concatMap((client) => from(client.getTokenSilently(options))),
            catchError((err, caught) => {
                this.logout();
                throw err;
            })
        );
    }

    public isInGroup$(group: GroupNames) {
        return this.groups$.pipe(
            map(groups => groups.includes(group))
        )
    }

    public login(redirectPath: string = '/', screenToOpen?: 'signup' | 'login') {
        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client) => {
            // Call method to log in

            client.loginWithRedirect({
                authorizationParams: {
                    action: screenToOpen,
                    redirect_uri: `${window.location.origin}/even-geduld`,
                },
                appState: {
                    target: redirectPath,
                }
            });
        });
    }

    private handleAuthCallback() {
        // Call when app reloads after user logs in with Auth0
        const params = window.location.search;
        if (params.includes('code=') && params.includes('state=')) {
            let targetRoute: string; // Path to redirect to after login processsed
            let queryParams: any;
            const authComplete$ = this.handleRedirectCallback$.pipe(
                // Have client, now call method to handle auth callback redirect
                tap((cbRes) => {
                    // Get and set target redirect route from callback results
                    targetRoute = cbRes.appState?.target ? cbRes.appState.target.split('?')[0] : '/';
                    queryParams = cbRes.appState?.target ? this.router.parseUrl(JSON.parse(JSON.stringify(cbRes.appState.target))).queryParams : {}
                    this.router.navigate([targetRoute], {
                        queryParams: queryParams
                    });
                }),
            );
            // Subscribe to authentication completion observable
            // Response will be an array of user and login status
            authComplete$.subscribe((user) => {
                this.authStateAfterParse$.next(true);
                // Redirect to target route after callback processing
                this.router.navigate([targetRoute], {
                    queryParams: queryParams
                });
            });
        }
    }

    logout() {
        // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client) => {
            // Call method to log out
            client.logout({
                clientId: environment.auth0ClientId,
                logoutParams: {
                    returnTo: `${window.location.origin}`
                }
            });
        });
    }

}