import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { Store } from '@ngxs/store';
import { AuthNewService } from '../services/auth-new.service';
import { take } from 'rxjs/operators';
import { GetUser } from '../states/models/user.state.model';
import { UserState } from '../states/user.state';

@Injectable({
  providedIn: CoreModule
})
export class UserResolver  {
  constructor(
    public store: Store, 
    private authService: AuthNewService
    ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const userProfile = await this.authService.userProfile$.pipe(take(1)).toPromise();
    await this.store.dispatch(new GetUser({userAuth0Id: userProfile.sub})).toPromise();
    return this.store.selectSnapshot(UserState.user);
  }
}
