import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action, createSelector } from '@ngxs/store';
import { OrderItem } from '../interfaces/order.model';
import {
  AddEmailToOrder,
  AddItemsToCart,
  RecalculateOrderItemPrices,
  RemoveItemFromCart,
  ResetCart,
  ShoppingCartStateModel
} from './models/shopping-cart.state.model';

@State<ShoppingCartStateModel>({
  name: 'shoppingCartv2',
  defaults: {
    orderItems: [],
    email: null,
  }
})
@Injectable()
export class ShoppingCartState {
  // Return all orderItems.
  @Selector([ShoppingCartState])
  static cartItems(state: ShoppingCartStateModel): OrderItem[] {
    return state.orderItems;
  }

  // Return total order price.
  @Selector([ShoppingCartState.cartItems])
  static orderPriceSum(state: OrderItem[]): number {
    const sellPrices = state.map(orderItem => parseFloat(orderItem.sellPrice) * orderItem.data.selectedOption.quantity);
    const sum = sellPrices.reduce((a, b) => a + b, 0);
    return sum;
  }

  // Return email.
  @Selector([ShoppingCartState])
  static email(state: ShoppingCartStateModel): string {
    return state.email;
  }

  // Dynamic selector notation for checking if a object is already within the cart.
  static isWithinCart(orderItem: OrderItem) {
    return createSelector(
      [ShoppingCartState.cartItems],
      (orderItemsInCart: OrderItem[]) => {
        return isWithinCart(orderItemsInCart, orderItem);
      }
    );
  }


  constructor() { }
  @Action(AddItemsToCart)
  addItemsToCart(ctx: StateContext<ShoppingCartStateModel>, action: AddItemsToCart) {
    // Add check if item can be added here.
    const currentCart = ctx.getState().orderItems;
    const itemsToBeAdded = action.payload.orderItems;

    const notWithinCart = itemsToBeAdded.filter(newItem => isWithinCart(currentCart, newItem) === false);

    if (notWithinCart.length === 0) {
      throw new Error('AlreadyInCartError');
    }

    return ctx.patchState({ orderItems: [...currentCart, ...notWithinCart] });
  }


  @Action(RemoveItemFromCart)
  removeItemFromCart(ctx: StateContext<ShoppingCartStateModel>, action: RemoveItemFromCart) {
    const currentCart = ctx.getState().orderItems;
    const filteredCart = currentCart.filter(orderItem => orderItem.id !== action.payload.orderItem.id);
    return ctx.patchState({ orderItems: filteredCart });
  }

  @Action(ResetCart)
  resetCart(ctx: StateContext<ShoppingCartStateModel>) {
    return ctx.patchState({ orderItems: [], email: null });
  }

  @Action(AddEmailToOrder)
  addEmailToOrder(ctx: StateContext<ShoppingCartStateModel>, action: AddEmailToOrder) {
    return ctx.patchState({ email: action.payload.email });
  }

  @Action(RecalculateOrderItemPrices)
  recalculateOrderItemPrices(ctx: StateContext<ShoppingCartStateModel>, action: RecalculateOrderItemPrices) {
    const currentCart = ctx.getState().orderItems;
    const newCart = currentCart.map(orderItem => {
      const newProduct = action.payload.products.find(product => product.id === orderItem.productId);

      if (!newProduct) {
        // This makes it so that the bulk based products arent recalculated.
        return orderItem;
      }

      return {
        ...orderItem,
        product: newProduct,
        sellPrice: newProduct.sellPrice
      };
    });

    return ctx.patchState({ orderItems: newCart });
  }
}

function isWithinCart(currentCart: OrderItem[], toAdd: OrderItem) {
  const itIs = currentCart.find(cartItem => cartItem.uniqueDescriptor === toAdd.uniqueDescriptor);
  return itIs !== undefined;
}