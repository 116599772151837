import { Injectable } from '@angular/core';
import { CoreModule } from '@app/core/core.module';
import { OrderItem } from '../interfaces/order.model';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: CoreModule
})
export class ShoppingCartService {

  constructor(
    private notificationService: NotificationService
  ) {

  }

  public async showCartv2(orderItems: OrderItem[]) {

    const dialog = await this.notificationService.getDialog()
    const { ShoppingCartComponent } = await import("@app/feature/shopping-cart/shopping-cart/shopping-cart.component");
    dialog.open(ShoppingCartComponent, {
      data: {
        addedOrderItemIds: orderItems.map(orderItem => orderItem.id)
      }
    })

  }

}
