import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthNewService } from '../services/auth-new.service';
import { mergeMap } from 'rxjs/operators';
import { CoreModule } from '@app/core/core.module';

@Injectable({
  providedIn: CoreModule
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(private auth: AuthNewService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // Add tokens if requests are made to our own backend.
    if (this.auth.loggedIn && request.url.includes(environment.apiDomain)) {
      return this.auth.getTokenSilently$().pipe(
        mergeMap(token => {
          const tokenReq = request.clone({
            headers: request.headers.append('Authorization', `Bearer ${token}`)
          });
          return next.handle(tokenReq);
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
