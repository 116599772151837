import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action, createSelector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Product } from '../interfaces/order.model';
import { ProductService } from '../services/product.service';
import { ListProducts } from './models/products.state.model';

@State<Product[]>({
  name: 'products',
  defaults: []
})
@Injectable()
export class ProductsState {
  // Return all products
  @Selector([ProductsState])
  static products(state: Product[]): Product[] {
    return state;
  }

  // Dynamic selector notation for returning only 1 product
  static getProductByName(name: string) {
    return createSelector(
      [ProductsState.products],
      (products: Product[]) => {
        return products.find(product => product.name === name);
      }
    );
  }

  constructor(private productService: ProductService) {}

  @Action(ListProducts)
  listProducts(ctx: StateContext<Product[]>) {
    return this.productService.list().pipe(
      tap(products => {
        ctx.setState(products);
      })
    );
  }
}
