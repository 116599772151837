export const NGXS_MIGRATIONS =  [
  {
    version: undefined,
    key: 'shoppingCart',
    versionKey: 'version',
    migrate: state => {
      return {
        orderItems: state,
        email: null,
        version: 1 // Important to set this to the next version!
      };
    }
  },
  {
    version: 1,
    key: 'shoppingCart',
    versionKey: 'version',
    migrate: state => {
      return {
        orderItems: [],
        email: null,
        version: 2 // Important to set this to the next version!
      };
    }
  },
  {
    version: undefined,
    key: 'app',
    versionKey: 'version',
    migrate: state => {
      return {
        ...state,
        survey1: false, 
        urlHistory: ['/'],
        version: 1 // Important to set this to the next version!
      };
    }
  },
  {
    version: undefined,
    key: 'map',
    versionKey: 'version',
    migrate: state => {
      return {
        ...state,
        measure: {
          advanced: false,
          snapping: true,
          angles: false,
          multiple: false
        },
        version: 1 // Important to set this to the next version!
      };
    }
  },

]